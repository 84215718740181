.button-style {
  height: 40px;
  background-color: white;
  border: 2px solid #3498db;
  border-radius: 85px;
  font-weight: 700;
  font-size: 15px;
  color: black;
  transition: 300ms ease-in-out;
  margin-left: 0px;
  text-decoration: none;
}

.button-style:hover {
  box-shadow: inset 200px 0 0 0 #3498db;
  color: white;
}
