.gallery-image-col {
  position: relative;
  overflow: hidden;
}

.gallery-image-col img {
  transition: transform 0.3s ease-in-out;
}

.gallery-image-col:hover img {
  transform: scale(1.1);
}

.hovered .hover-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: start;
  pointer-events: none;
}

.hover-content h1 {
  margin: 10px;
  color: #fff;
  font-size: 20px;
  text-align: start;
}
.plus-Icon {
  margin: 10px;
  color: #fff;
  font-size: 30px;
  text-align: start;
}

.custom-modal {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
}

ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.filter-item {
  color: rgb(78, 75, 75);
  border: none;
  padding: 8px;
  text-align: center;
  font-size: 17px;
  display: inline-block;
  border-radius: 10px;
  transition-duration: 1s;
  opacity: 0.7;
  cursor: pointer;
  background-color: transparent;
  margin-left: 5px;
  clip-path: polygon(0 0, 90% 0, 75% 100%, 0 100%);
  height: 41px;
}
.filter-item:hover {
  background-color: #3498db;
  color: white;
  clip-path: polygon(0 0, 90% 0, 75% 100%, 0 100%);
  width: 110px;
}
.active-filter {
  background-color: #3498db;
  clip-path: polygon(0 0, 90% 0, 75% 100%, 0 100%);
  color: white;
}
