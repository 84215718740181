p {
  text-align: start;
}
.modal-content {
  background: rgba(29, 29, 39, 0.7);
}
.mycard {
  transition: 300ms ease-in-out;
  height:24rem;
  background-position:0;
}

.mycard-title {
  font-weight: bold;
}
.mycard:hover {
  transform: scale(1.0) ;
  cursor: pointer;
  bottom: 0;
  height:24rem;

   transition: 0.8s; 
   background-image: linear-gradient(45deg, #67acda 50%,white 50%);
   background-position:100%; 
   background-size: 300%;
}

.mycard button:hover {
  background-color: white;
  color: black;
}